<template>
    <v-layout d-flex column class="fw fh">
        <v-flex grow class="d-flex text-center justify-center align-center ">
                <img src="@/assets/logo-white.png" />
            
        </v-flex>
        <v-flex grow class="d-flex text-center justify-center align-center">
            <img :src="src" :class="{ xs: breakpoint.xs}" /> 
        </v-flex>
        <v-flex grow class="d-flex text-center justify-center align-center">
            <div class="title white--text">
               أتمام الملف الشخصي
            </div>
        
        </v-flex>
    </v-layout>
</template>
<script>
export default {
    name:'AuthLogin',
    props: ['loading'],
    data: () => ({
        src: require('@/assets/complete-profile.png')
    })
}
</script>
<style scoped>
img {
    max-width: 200px;
    margin: 0 auto;
    max-height: 55%;
}
</style>