<template>
    <div id="login-card-end" class="  pa-4 fh fw justify-center d-flex flex-column">
 
        <div v-if="!success">
            <v-alert  class="font-weight-bold">
                أدخل البريد الإلكتروني المرتبط بحسابك وسنرسل لك رابط لإعادة تعيين كلمة المرور الخاصة بك
            </v-alert>
            <v-form @submit.prevent="submit" ref="form" :disabled="loading">
                <v-text-field
                prepend-inner-icon="mdi-email"
                name="email"
                light
                dense
                rounded
                outlined
                required
                :rules="getFieldRule('required| email')"
                label="البريد الالكتروني"
                ></v-text-field>
    
                <div style="width:100%">
                    <v-btn large light block rounded class="my-2" color="success" @click="submit" :loading="loading" >
                    أرسل الرابط
                    </v-btn>
                </div>
        
            </v-form>
            <v-alert class="text-center" width="100%">
                ليس لديك حساب لدينا؟ <v-btn text  color="success" to="/auth?mode=Register" class="font-weight-bold px-1"> أشترك الان</v-btn> مجاناً
            </v-alert>
        </div>
         <div v-else class="text-center" >
            <v-icon x-large color="success" class="my-3">mdi-send-check-outline</v-icon>  
             <div class="title  my-3">
                   تم أرسال رابط لتعيين كلمة مرور جديدة الى "<strong>{{ email }}</strong>"
             </div>
             <v-btn text  color="info" to="/auth?mode=Login" class="font-weight-bold px-1"> تسجيل الدخول</v-btn>
        </div>
        

    </div>
</template>
<script>
export default {
    name: 'AuthLogin',
    props: ['loading'],
    data: () => ({
        success: false,
        email: null
    }),
    methods: {
        
        submit () {
            const { form } = this.$refs
            if (!form.validate()) return
            const { lazyValue: email } = form.inputs.find(input => input.$attrs.name == 'email')
           
            this.$emit('update:loading', true)
          
            this.sendPasswordReset(email).then(res => {
                console.log(res);
                this.email = email
                this.success = true
            }).catch(this.handelError)
                .finally( () => this.$emit('update:loading', false))
        }
    }
}
</script>