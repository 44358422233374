6

<template>
    <div id="login-card-end" class="  pa-4 fh fw justify-center d-flex flex-column">
        <div v-if="!success">
            <v-alert  class="font-weight-bold" icon="mdi-form-textbox-password">
                أعادة تعيين كلمة المرور جديدة
            </v-alert>
            <v-form @submit.prevent="submit" ref="form" :disabled="loading">
                <v-password
                prepend-inner-icon="mdi-lock"
                name="password"
                light
                dense
                rounded
                outlined
                required
                :rules="getFieldRule('required|min(6)|max(12)')"
                label="كلمة المرور الجديدة"
                show-strength
                ></v-password>
    
                <div style="width:100%">
                    <v-btn large light block rounded class="my-2" color="success" @click="submit" :loading="loading" >
                    أعادة تعيين كلمة المرور
                    </v-btn>
                </div>
        
            </v-form>
        </div>
        <div v-else class="text-center" >
            <v-icon x-large color="success" class="my-3">mdi-lock-check</v-icon>  
             <div class="title  my-3">
                  تم أعادة تعيين كلمة المرور بنجاح 
             </div>
             <v-btn text  color="info" to="/auth?mode=Login" class="font-weight-bold px-1"> تسجيل الدخول</v-btn>
        </div>
       
        

    </div>
</template>
<script>
import VPassword from '../../../components/VPassword.vue'
export default {
  components: { VPassword },
    name: 'AuthLogin',
    props: ['loading', 'oobCode'],
    data: () => ({
        success: false
    }),
    methods: {
      
        submit () {
            const { form } = this.$refs
            if (!form.validate()) return
            console.log(form.inputs.find(input => input.$attrs.name == "password"));
            const { lazyValue: password } = form.inputs.find(input => input.$attrs.name == "password")
            console.log(password);
            this.$emit('update:loading', true)
          
            this.confirmPasswordReset(this.oobCode, password).then(() => {
                this.success = true
            }).catch(this.handelError)
                .finally( () => this.$emit('update:loading', false))
        }
    }
}
</script>