<template>
    <div id="login-card-end" class=" fh fw justify-center d-flex flex-column">
        <v-card flat>
            <v-card-title class="justify-center" v-if="!breakpoint.mobile">
                ملفك الشخصي 

               
            </v-card-title>
            <v-card-title >
                <v-flex class="text-center">
                    <v-profile-img size="100"  class="mx-auto" @change="change" :value="src" ></v-profile-img>
                </v-flex>
            </v-card-title>
            <v-card-text class="text-center">
                <v-form @submit.prevent="submit" ref="form" :disabled="loading" class="mb-6">

                    <v-text-field
                    rounded
                        outlined
                        dense
                        label="الأسم"
                        name="displayName"
                        prepend-inner-icon="mdi-account"
                        :value="currentUser.displayName"
                        required
                        :rules="getFieldRule('required')"
                    >
                    </v-text-field>
                   
                    <v-btn large rounded color="success" @click="submit" :loading="loading">
                        أتمام الملف الشخصي
                    </v-btn>
                </v-form>
                    <v-btn  rounded  small text color="secondary" @click="logout" :disabled="loading">
                        <v-icon small>mdi-logout</v-icon>
                        تسجيل الخروج
                    </v-btn>
            </v-card-text>
    
        </v-card>
        

    </div>
</template>
<script>
export default {
    name: 'AuthLogin',
    props: ['loading'],
    data: () => ({
        src: require('@/assets/profile-img.png'),
    }),
    watch: {
        currentUser: {
            handler (currentUser) {
                if (currentUser.photoURL)
                    this.src = currentUser.photoURL
            },
            immediate: true
        }
    },
    methods: {
        change (src) {
            this.src = src
        },
        submit () {

            
            const { form } = this.$refs
            if (!form.validate()) return
            const data = {  };
            form.inputs.forEach(input => {
                data[input.$attrs.name] =input.lazyValue
            })

            data.photoURL = this.src

            const { displayName, photoURL } = data

            this.$emit('update:loading', true)
            this.updateProfile({ displayName, photoURL })
                .then(() => {
                this.$router.replace('/dashboard')
            }).catch(this.handelError).finally(() => this.$emit('update:loading', false))


        }
    }
  
    
}
</script>
 