import { render, staticRenderFns } from "./CardStart.vue?vue&type=template&id=c15a8c4e&scoped=true&"
import script from "./CardStart.vue?vue&type=script&lang=js&"
export * from "./CardStart.vue?vue&type=script&lang=js&"
import style0 from "./CardStart.vue?vue&type=style&index=0&id=c15a8c4e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c15a8c4e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VFlex,VLayout,VProgressCircular})
