<template>
    <v-layout d-flex column class="fw fh py-4 " :class="{ 'px-12': !breakpoint.xs, 'px-4': breakpoint.xs }">

        <v-flex grow class="d-flex text-center justify-center align-center flex-column" >
           <v-subheader light class="my-1 fw">    
                قم بتسجيل الدخول
                  <v-spacer></v-spacer>
                <v-btn  light   rounded text class="my-2" color="secondary" to="/auth?mode=phoneLogin" :disabled="loading">
                    <v-icon>mdi-cellphone-message</v-icon>
                   SMS
                </v-btn>
            </v-subheader>
             <v-row class="fw">
                <v-col cols="6" class="px-1">
                    <v-btn rounded depressed  block  color="#4267B2" class="mx-1 white--text" :disabled="loading" @click="signinWith('facebook')">
                        <v-icon class="mx-1">mdi-facebook</v-icon>
                        <span class="pt-1 d-inline-block">
                            فيسبوك
                        </span>
                    </v-btn>
                </v-col>
                
                <v-col cols="6" class="px-1">
                    <v-btn rounded  depressed  block  color="#DB4437" class="mx-1 white--text" :disabled="loading" @click="signinWith('google')">
                        <v-icon class="mx-1">mdi-google</v-icon>
                        <span class="pt-1 d-inline-block">
                            جوجل
                        </span>

                    </v-btn>
                </v-col>
              
            </v-row>
            <v-subheader light class="fw">    
                <v-divider light></v-divider>
                او بواسطة البريد الإلكتروني  
                <v-divider light></v-divider>
            </v-subheader>
            <v-form @submit.prevent="signin" ref="form" :disabled="loading" class="fw">
                <v-text-field
                prepend-inner-icon="mdi-email"
                name="email"
                light
                dense
                rounded
                outlined
                required
                :rules="getFieldRule('required| email')"
                label="البريد الالكتروني"
                ></v-text-field>
                <v-password
                prepend-inner-icon="mdi-lock"
                light
                required
                :rules="getFieldRule('required')"
                rounded
                outlined
                dense
                name="password"
                label="كلمة المرور"
                ></v-password>
                <v-row>
                    <v-col sm="8">
                        <v-btn  light block rounded  depressed class="my-2" color="primary" @click="signin" :disabled="loading" >
                            تسجيل الدخول
                        </v-btn>
                    </v-col>
                    <v-col sm="4">

                        <v-btn  light  rounded link text class="my-2" color="primary" to="/auth?mode=forgetPassword" :disabled="loading">
                            نسيت كلمة المرور؟
                        </v-btn>
                    </v-col>
                </v-row>
             
                
            </v-form>
      
   
           
        </v-flex>
        <v-flex shrink >
            
            <v-alert  width="100%">
                ليس لديك حساب ؟ <v-btn text  color="success" to="/auth?mode=Register" class="font-weight-bold px-1"> أشترك الان</v-btn> مجاناً
            </v-alert>
              
        </v-flex>
    </v-layout>
    
</template>
<script>
export default {
    name: 'AuthLogin',
    props: ['loading'],
    methods: {
        signinWith(provider) {
            this.$emit('update:loading', true)
            this.login({ provider })
                .finally( () => this.$emit('update:loading', false))
        },
        signin () {
            const { form } = this.$refs
            if (!form.validate()) return
            this.$emit('update:loading', true)
            const data = { email: '', password: '' };
            form.inputs.forEach(input => {
                data[input.$attrs.name] =input.lazyValue
            })
            const { email, password } = data 
            this.login({ provider: 'password', email, password  })
                .finally( () => this.$emit('update:loading', false))
        }
    }
}
</script>