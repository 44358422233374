<template>
    <v-main id="auth-view">
        <v-img src="./../assets/auth.png" class="fh">
            <v-sheet class="fh d-flex align-center justify-center" color="transparent">
                <v-card :width="width" :class="{ 'fh': !breakpoint.mdAndUp }" >
                    <v-card-text class="pa-0 hide-overflow fh">
                        <v-layout d-flex class="white fh" :column="flip" :style="!breakpoint.mdAndUp ? '': 'min-height: 400px'">
                            <v-flex class="primary  hide-overflow relative " :style="startStyle" >
                                
                                <component v-if="startView" :is="startView" :breakpoint="breakpoint" :loading.sync="loading" :oobCode="oobCode"/>
                            </v-flex>
                            <v-flex :style="endStyle" :class="[ 'hide-overflow relative elevation-4']">
                                
                                <component v-if="endView" :is="endView" :breakpoint="breakpoint" :loading.sync="loading" :oobCode="oobCode" />
                            </v-flex>
                        </v-layout>
                    </v-card-text>
                </v-card>
            
            </v-sheet>
        </v-img>
        <router-view></router-view>
    </v-main>
</template>
<script>
export default {
    name: 'Auth',
    props: ['mode', 'oobCode'],
    data: () => ({
        loading: false,
        startView: null,
        endView: null

    }),
    watch: {
        mode:{
            handler: 'checkMode',
            immediate: true
        }
    },
    computed: {
       
        authModeDirs () {
                    function onlyUnique(value, index, self) {
                return self.indexOf(value) === index;
            }
            const dirs = require.context('./AuthMode/')
            return dirs.keys().map(e => e.split('/')[1]).filter(onlyUnique)
           
        },
        flip() {
            const { height, width } = this.breakpoint
            return height > width
        },
        startStyle () {
            return { 'flex-basis': this.sizeStart, [`max-${this.flip ? 'height' : 'width'}`]: this.sizeStart }
        },
        endStyle () {
            return { 'flex-basis': this.sizeEnd, [`max-${this.flip ? 'height' : 'width'}`]: this.sizeEnd }
        },
        sizeStart() {
            return '35%'
        },
        sizeEnd() {
            return '65%'

        },
     
        width () {
            return   !this.breakpoint.mdAndUp ? '100%' : 940
        } 
    },
    methods: {
        checkMode (mode) {
            const dir = this.authModeDirs.find(dir => dir.toLowerCase() == mode.trim().toLowerCase())
            
            if (!dir) 
                return this.$router.replace('/404')
           
            this.startView = () => import('./AuthMode/'+dir+'/CardStart.vue') 
            this.endView = () => import('./AuthMode/'+dir+'/CardEnd.vue') 
        }
    } 
}
</script>
<style lang="scss">

#auth-view {
   img {
       max-width: 160px;
        max-height: 160px;
   }
   img.xs {
        max-height: 100px;
   }
   .v-btn:before {
       background-color: transparent !important;
   }
}
</style>