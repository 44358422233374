<template>
    <div id="login-card-end" class="  pa-4 fh fw justify-center d-flex flex-column">
        <div v-if="!success && !error">
            <div class="text-center" v-if="!error">

                <v-progress-circular
                size="60"
                class="my-3"
                indeterminate
                ></v-progress-circular>
                <div class="title">يتم تأكيد ملكيتك للبريد الالكتروني بنجاح</div>
            </div>
        </div>
        <div v-else-if="oobCode && success" class="text-center" >
            <v-icon x-large color="success" class="my-6">mdi-email-check</v-icon>  
             <div class="title  my-3">
                تم تأكيد ملكيتك للبريدك الالكتروني بنجاح
             </div>
        </div>


       <div v-if="error" class="text-center" >
            <v-icon size="60" color="error" class="my-6">mdi-close-circle</v-icon>  
             <div class="title  my-3">
                {{error}}
             </div>
        </div>
        

    </div>
</template>
<script>
export default {
    name: 'AuthLogin',
    props: ['loading', 'oobCode'],
    data: () => ({
        success: false,
        error: null
    }),
    watch: {
        oobCode :{
            handler: 'submit',
            immediate: true
        }
    },  
    methods: {
        
        submit () {
            if (!this.oobCode) return this.error = this.getAuthError({ code: 'auth/invalid-action-code' })
            this.$emit('update:loading', true)
          
            this.applyActionCode(this.oobCode).then(() => {
                this.success = true
                setTimeout(() => {
                    this.$router.replace('/dashboard')
                },500)
            }).catch(e => {
                this.error = this.getAuthError(e)
                 
            })
                .finally( () => this.$emit('update:loading', false))
        }
    }
}
</script>