<template>
    <div id="login-card-end" class="  pa-4 fh fw justify-center d-flex flex-column">
        
      
        <div v-if="!success && !confirmation" >
           <v-alert  class="font-weight-bold" icon="mdi-cellphone-message">
            قم بأدخال رقم الهاتف الخاص بك ليصلك رمز التأكيد برسالة نصية الى جهازك
            </v-alert>
            
            <v-form @submit.prevent="submit" ref="form" :disabled="loading">
                <div v-if="loading" class="text-center">
                        <div class="title">يتم أرسال رمز التأكيد...</div>
                </div>
                <v-phone v-else
                v-model="phone"
                @update="results = $event"
                ref="phoneNumber"
                ></v-phone>
    
                <div style="width:100%" class="my-6" v-if="results">
                 
                    <v-btn large light block rounded class="my-2" color="success" @click="submit" :disabled="!results.isValid" :loading="loading" id='verify-phone-button' >
                      أرسل رمز التأكيد
                    </v-btn>
                </div>
                
        
            </v-form>
        </div>
        <div v-if="confirmation">
            <v-alert  class="font-weight-bold" icon="mdi-cellphone-key">
             تأكيد رقم  الهاتف
            </v-alert>
            <v-text-field
            label="أدخل رمز التأكيد"
            maxlength="6"
            outlined
            solo
            class="code-input"
            v-model="code"
            @input="verifyCode"
            :loading="loading"
            :rules="[ v => !!v || 'هذا الحقل مطلوب']"
            ></v-text-field>
        </div>
        <div class="text-center" v-if="success" >
            <v-icon x-large color="success" class="my-6">mdi-check-circle</v-icon>  
             <div class="title  my-3">
                تم تأكيد رقم الهاتف بنجاح
             </div>
        </div>
       
        

    </div>
</template>
<script>
export default {
    name: 'AuthLogin',
    props: ['loading'],
    data: () => ({
        phone: null,
        success: false,
        confirmation: null,
        results: null,
        code: ''
        
    }),

    methods: {
        verifyCode () {
            if(this.code.length != 6 && this.confirmation) return;
            this.$emit('update:loading', true)
            const credential = this.getPhoneCodeCredential(this.confirmation.verificationId, this.code);


           
            this.signInwithCredential(credential).then(() => {
                this.success = true
                this.confirmation = null
            }).catch(this.handelError).finally(this.$emit('update:loading', false))
        },
        submit () {
            const { phoneNumber } = this.$refs
            const [ phoneInput ] = phoneNumber.$children
            if(!phoneInput.isValid) return 

            const verifier = this.getRecaptchaVerifier('verify-phone-button') 
            

            this.$emit('update:loading', true)
            this.sendPhoneNumberCode(this.results.formattedNumber, verifier).then( confirmation => {
                this.notify(`تم أرسال رمز التأكيد الى الرقم "${this.results.formattedNumber}" بنجاح`, { color: 'success', icon: 'mdi-check-circle' })
                this.confirmation = confirmation

            }).catch(this.handelError).finally( () => {
                this.$emit('update:loading', false)
                verifier.reset()
            })
        }
    }
}
</script>